import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  alpha,
  Backdrop,
  CircularProgress,
  FormControl,
  TableRow,
  TableCell,
  Paper,
  makeStyles,
  Button,
  Box,
  Menu,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Select,
  MenuItem,
  InputLabel,
  Tooltip,
  InputBase,
  Grid,
  TableContainer,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { getSignee } from "../../store/action";
import history from "../../utils/HistoryUtils";
import FilterListIcon from "@material-ui/icons/FilterList";
import axios from "axios";
import apiConfigs from "../../config/config";
import { TextField } from "@mui/material";
import { getStaffDetail } from "../../store/action/staff/staffAction";
import { getSpecShiftCreate } from "../../store/action/specialities/specialitiesAction";
import {
  createFilter,
  deleteFilter,
} from "../../store/action/booking/bookingAction";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { customEnLocale } from "../../helper/customLocale";
import { getFilterData } from "../../store/action/signee/signeeAction";
import EnhancedTableHead from "./EnhancedTableHead";
import { headCells } from "./Headcells";
import { green } from "@material-ui/core/colors";
import { useGooglePlaceAutoCompleteForCandidate } from "../../components/GoogleAPI/google_place_autocomplete";

const useStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    // overflowX: "auto",
    padding: 24,
    marginBottom: 0,
  },

  container: {
    marginTop: "15px",
    maxHeight: "calc(100vh - 260px)",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(6),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing(),
    paddingRight: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingLeft: theme.spacing(1),
    transition: theme.transitions.create("width"),
    width: "100%",
    borderBottom: "1px solid #ccc",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  viewBtn: {
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    width: "25%",
  },
  formControl1: {
    width: 120,
  },
  filterBookingSearch: {
    "& .MuiPaper-root": {
      right: "30px",
      maxWidth: "970px",
      width: "100%",
      left: "unset !important",
    },
    "& .MuiList-root": {
      padding: "20px",
    },
  },
  filterBox: {
    padding: 12,
  },
  radioGroup: {
    flexDirection: "row",
  },
}));
const ViewSignee = ({ match }) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { getSigneeItem, loading, getVCFilterDataSuccess } = useSelector(
    (state) => state.signee
  );
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchData, setSearchData] = useState({
    search: "",
    new_status: "",
    specialty: "",
    postcode: "",
    consultants: "",
    module_name: "candidates",
    proximity: "",
    start_date:"",
    end_date:""
  });
  const address1Ref = useRef();
  const googleAutoCompleteSvc = useGooglePlaceAutoCompleteForCandidate();
  let autoComplete = "";

  const handleAddressSelect = async () => {
    let addressObj = await googleAutoCompleteSvc.getFullAddress(autoComplete);
    setSearchData((prevData) => ({
      ...prevData,
      postcode: addressObj.address_line_1,
      latitude: addressObj.latitude,
      longitude: addressObj.longitude,
    }));
  };
  const handleChange = (event) => {
    async function loadGoogleMaps() {
      // initialize the Google Place Autocomplete widget and bind it to an input element.
      // eslint-disable-next-line
      autoComplete = await googleAutoCompleteSvc.initAutoComplete(
        address1Ref.current,
        handleAddressSelect
      );
    }
    loadGoogleMaps();
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  let staffDetail = JSON.parse(localStorage.getItem("staffDetail"));

  staffDetail = staffDetail && staffDetail.replace(/['"]+/g, "");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("users.first_name");

  const handleSearchChange = (event) => {
    const d1 = event.target.value;
    if (d1) {
      setTimeout(
        getSigneeList(
          searchPage,
          d1,
          searchData?.new_status
            ? searchData?.new_status
            : getVCFilterDataSuccess?.data?.new_status,
          searchData?.specialty
            ? searchData?.specialty
            : getVCFilterDataSuccess?.data?.specialty,
          searchData?.postcode
            ? searchData?.postcode
            : getVCFilterDataSuccess?.data?.postcode,
          searchData?.consultants
            ? searchData?.consultants
            : getVCFilterDataSuccess?.data?.consultants,
          "",
          "",
          searchData?.proximity
            ? searchData?.proximity
            : getVCFilterDataSuccess?.data?.proximity,
          searchData?.latitude
            ? searchData?.latitude
            : getVCFilterDataSuccess?.data?.latitude,
          searchData?.longitude
            ? searchData?.longitude
            : getVCFilterDataSuccess?.data?.longitude,
          searchData?.start_date
          ? moment(searchData?.start_date).format("YYYY-MM-DD")
          : "",
          searchData?.end_date
            ? moment(searchData?.end_date).format("YYYY-MM-DD")
            : "",
        ),
        100
      );
    } else {
      setTimeout(
        getSigneeList(
          page,
          "",
          searchData?.new_status
            ? searchData?.new_status
            : getVCFilterDataSuccess?.data?.new_status,
          searchData?.specialty
            ? searchData?.specialty
            : getVCFilterDataSuccess?.data?.specialty,
          searchData?.postcode
            ? searchData?.postcode
            : getVCFilterDataSuccess?.data?.postcode,
          searchData?.consultants
            ? searchData?.consultants
            : getVCFilterDataSuccess?.data?.consultants,
          "",
          "",
          searchData?.proximity
            ? searchData?.proximity
            : getVCFilterDataSuccess?.data?.proximity,
          searchData?.latitude
            ? searchData?.latitude
            : getVCFilterDataSuccess?.data?.latitude,
          searchData?.longitude
            ? searchData?.longitude
            : getVCFilterDataSuccess?.data?.longitude,
          searchData?.start_date
          ? moment(searchData?.start_date).format("YYYY-MM-DD")
          : "",
          searchData?.end_date
            ? moment(searchData?.end_date).format("YYYY-MM-DD")
            : "",
        ),
        100
      );
      setSearchPage(1);
    }
    setSearchData({ ...searchData, [event.target.name]: event.target.value });
  };

  const handleMenu = (event) => {
    if (
      searchData.search === "" &&
      searchData.new_status === "" &&
      searchData.specialty === "" &&
      searchData.postcode === "" &&
      searchData.consultants === "" &&
      searchData.start_date === "" &&
      searchData.end_date === ""
    ) {
      getFilterDataByModule();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  // filter status store
  const handleChangeFilterStatus = (event) => {
    setSearchData({ ...searchData, status: event.target.value });
  };

  const handleClickSearch = (event, value) => {
    handleMenuClose(true);
    setTimeout(() => {
      getSigneeList(
        page,
        searchData?.search
          ? searchData?.search
          : getVCFilterDataSuccess?.data?.search,
        searchData?.new_status
          ? searchData?.new_status
          : getVCFilterDataSuccess?.data?.new_status,
        searchData?.specialty
          ? searchData?.specialty
          : getVCFilterDataSuccess?.data?.specialty,
        searchData?.postcode
          ? searchData?.postcode
          : getVCFilterDataSuccess?.data?.postcode,
        searchData?.consultants
          ? searchData?.consultants
          : getVCFilterDataSuccess?.data?.consultants,
        "",
        "",
        searchData?.proximity
          ? searchData?.proximity
          : getVCFilterDataSuccess?.data?.proximity,
        searchData?.latitude
          ? searchData?.latitude
          : getVCFilterDataSuccess?.data?.latitude,
        searchData?.longitude
          ? searchData?.longitude
          : getVCFilterDataSuccess?.data?.longitude,
        searchData?.start_date
        ? moment(searchData?.start_date).format("YYYY-MM-DD")
        : "",
        searchData?.end_date
          ? moment(searchData?.end_date).format("YYYY-MM-DD")
          : "",
      )
    }
    , 1000  
    )
  };

  const getSigneeList = (
    pageNo = 1,
    search = "",
    new_status = "",
    specialty = "",
    postcode = "",
    consultants = "",
    type = order,
    column_name = orderBy,
    proximity = "",
    latitude = "",
    longitude = "",
    startDate = "",
    endDate = ""
  ) => {
    dispatch(
      getSignee({
        pageNo,
        search,
        new_status,
        specialty,
        postcode,
        consultants,
        type,
        column_name,
        proximity,
        latitude,
        longitude,
        startDate,
        endDate
      })
    );
  };
  const [speciality, setSpeciality] = useState([]);
  const [specError, setSpecError] = useState(false);
  const [staff, setStaff] = useState([]);
  const loginUserInfo = JSON.parse(
    window.localStorage.getItem("loginUserInfo")
  );

  const getStaff = async () => {
    dispatch(
      getStaffDetail((result) => {
        if (result?.data) {
          setStaff(result);
        }
      })
    );
  };

  const getSpecialities = async () => {
    dispatch(
      getSpecShiftCreate((result) => {
        if (result?.status === true) {
          setSpeciality(result);
        } else {
          setSpecError(true);
        }
      })
    );
  };

  useEffect(() => {
    getSpecialities();
    getStaff();
    getFilterDataByModule();
    // const today = new Date();
    // const defaultStartDate = new Date(
    //   today.getTime() - 7 * 24 * 60 * 60 * 1000
    // );
    // let iStartDate = searchData?.start_date
    //   ? moment(searchData?.start_date).format("YYYY-MM-DD")
    //   : moment(defaultStartDate).format("YYYY-MM-DD");
    // let sStartDate = getVCFilterDataSuccess?.data?.start_date
    //   ? moment(getVCFilterDataSuccess?.data?.start_date).format("YYYY-MM-DD")
    //   : moment(defaultStartDate).format("YYYY-MM-DD");
    // iStartDate = iStartDate !== "" ? iStartDate : sStartDate;
    // setStartDate(iStartDate);
    // let iEndDate = searchData?.end_date
    //   ? moment(searchData?.end_date).format("YYYY-MM-DD")
    //   : moment(today).format("YYYY-MM-DD");
    // let sEndDate = getVCFilterDataSuccess?.data?.end_date
    //   ? moment(getVCFilterDataSuccess?.data?.end_date).format("YYYY-MM-DD")
    //   : moment(today).format("YYYY-MM-DD");
    // iEndDate = iEndDate !== "" ? iEndDate : sEndDate;
    // setEndDate(iEndDate);

  }, []);

  const onhandlClick = (id) => {
    history.push(`${match.url}/${id}/detail`);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
    setTimeout(
      getSigneeList(
        value,
        searchData?.search
          ? searchData?.search
          : getVCFilterDataSuccess?.data?.search,
        searchData?.new_status
          ? searchData?.new_status
          : getVCFilterDataSuccess?.data?.new_status,
        searchData?.specialty
          ? searchData?.specialty
          : getVCFilterDataSuccess?.data?.specialty,
        searchData?.postcode
          ? searchData?.postcode
          : getVCFilterDataSuccess?.data?.postcode,
        searchData?.consultants
          ? searchData?.consultants
          : getVCFilterDataSuccess?.data?.consultants,
        "",
        "",
        searchData?.proximity
          ? searchData?.proximity
          : getVCFilterDataSuccess?.data?.proximity,
        searchData?.latitude
          ? searchData?.latitude
          : getVCFilterDataSuccess?.data?.latitude,
        searchData?.longitude
          ? searchData?.longitude
          : getVCFilterDataSuccess?.data?.longitude
      ),
      2000
    );
  };

  const handleReset = () => {
    let data = {
      search: "",
      new_status: "",
      specialty: "",
      postcode: "",
      consultants: "",
      start_date: "",
      end_date:"",
      module_name: "candidates",
    };
    setSearchData(data);
    handleMenuClose(true);
    dispatch(deleteFilter(data, "candidates"));
    dispatch({ type: 'EMPTY_VC_FILTER_DATA' });
    getSigneeList(page, "", "")
  };

  const handleDownload = async (event, value) => {
    const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
    const new_status = searchData?.new_status
      ? searchData?.new_status
      : getVCFilterDataSuccess?.data?.new_status;
    const postcode = searchData?.postcode
      ? searchData?.postcode
      : getVCFilterDataSuccess?.data?.postcode;
    const proximity = searchData?.proximity
      ? searchData?.proximity
      : getVCFilterDataSuccess?.data?.proximity;
    const consultants = searchData?.consultants
      ? searchData?.consultants
      : getVCFilterDataSuccess?.data?.consultants;
    const specialty = searchData?.specialty
      ? searchData?.specialty
      : getVCFilterDataSuccess?.data?.specialty;
    const search = searchData?.search
      ? searchData?.search
      : getVCFilterDataSuccess?.data?.search;
    const latitude = searchData?.latitude
      ? searchData?.latitude
      : getVCFilterDataSuccess?.data?.latitude;
    const longitude = searchData?.longitude
      ? searchData?.longitude
      : getVCFilterDataSuccess?.data?.longitude;

    await axios
      .get(
        `${apiConfigs.API_URL}api/organization/download-candidates-csv?search=${
          search || ""
        }&new_status=${new_status || ""}&postcode=${postcode || ""}&proximity=${
          proximity || ""
        }&consultantsId=${consultants || ""}&specialty=${specialty || ""}&latitude=${latitude || ""}&longitude=${
          longitude || ""
        }`,
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${loggedInUser}`,
          },
        }
      )
      .then((response) => {
        const url = response.data.data;
        var link = document.createElement("a");
        link.download = "Candidate.csv";
        link.href = url;
        link.target = "_blank";
        link.click();
      })
      .catch((error) => {
        console.error("error: ", error);
      });
  };

  const getFilterDataByModule = () => {
    let data = {
      search: "",
      new_status: "",
      specialty: "",
      postcode: "",
      consultants: "",
      compliance: "",
      start_date: "",
      end_date: "",
      module_name: "candidates",
    };
    dispatch(getFilterData("candidates"));
    if (getVCFilterDataSuccess?.data) {
      if (getVCFilterDataSuccess?.data?.status !== "") {
        data.new_status = getVCFilterDataSuccess?.data?.new_status;
      }
      if (getVCFilterDataSuccess?.data?.specialty !== "") {
        data.specialty = getVCFilterDataSuccess?.data?.specialty;
      }
      if (getVCFilterDataSuccess?.data?.postcode !== "") {
        data.postcode = getVCFilterDataSuccess?.data?.postcode;
      }
      if (getVCFilterDataSuccess?.data?.consultants !== "") {
        // data.consultants = getVCFilterDataSuccess?.data?.consultants;
      }
      if (getVCFilterDataSuccess?.data?.start_date) {
        data.start_date = getVCFilterDataSuccess?.data?.start_date;
      }
      if (getVCFilterDataSuccess?.data?.end_date) {
        data.end_date = getVCFilterDataSuccess?.data?.end_date;
      }
      setSearchData(data);
    }
  };

  const handleClickSave = async () => {
    dispatch(createFilter(searchData, "candidates"));
    setPage(1);
    if (getVCFilterDataSuccess?.data?.end_date) {
      setSearchData({
        ...searchData,
        end_date: getVCFilterDataSuccess?.data?.end_date,
      });
    }

    let iStartDate = searchData?.start_date
      ? moment(searchData?.start_date).format("YYYY-MM-DD")
      : "";
    let sStartDate = getVCFilterDataSuccess?.data?.start_date
      ? moment(getVCFilterDataSuccess?.data?.start_date).format("YYYY-MM-DD")
      : "";
    iStartDate = iStartDate !== "" ? iStartDate : sStartDate;

    setSearchData({
      ...searchData,
      start_date: iStartDate,
    }); 
    let iEndDate = searchData?.end_date
      ? moment(searchData?.end_date).format("YYYY-MM-DD")
      : "";
    let sEndDate = getVCFilterDataSuccess?.data?.end_date
      ? moment(getVCFilterDataSuccess?.data?.end_date).format("YYYY-MM-DD")
      : "";
    
    iEndDate = iEndDate !== "" ? iEndDate : sEndDate;
    
    setSearchData({
      ...searchData,
      end_date: iEndDate,
    });
    
    getSigneeList(
      1,
      searchData?.search
        ? searchData?.search
        : getVCFilterDataSuccess?.data?.search,
      searchData?.new_status
        ? searchData?.new_status
        : getVCFilterDataSuccess?.data?.new_status,
      searchData?.specialty
        ? searchData?.specialty
        : getVCFilterDataSuccess?.data?.specialty,
      searchData?.postcode
        ? searchData?.postcode
        : getVCFilterDataSuccess?.data?.postcode,
      searchData?.consultants
        ? searchData?.consultants
        : getVCFilterDataSuccess?.data?.consultants,
      order,
      orderBy,
      searchData?.proximity
        ? searchData?.proximity
        : getVCFilterDataSuccess?.data?.proximity,
      searchData?.latitude
        ? searchData?.latitude
        : getVCFilterDataSuccess?.data?.latitude,
      searchData?.longitude
        ? searchData?.longitude
        : getVCFilterDataSuccess?.data?.longitude,
      searchData?.start_date ? moment(searchData?.start_date).format('YYYY-MM-DD')  
      : getVCFilterDataSuccess?.data?.start_date,
      searchData?.end_date
        ? moment(searchData?.end_date).format('YYYY-MM-DD')
        : getVCFilterDataSuccess?.data?.end_date,
    );
    handleMenuClose(true);
  };

  const handleRequestSort = (
    property,
    setOrder,
    setOrderBy,
    orderBy,
    order
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  useEffect(() => {
    if (searchData || getVCFilterDataSuccess?.data) {
      getSigneeList(
        1,
        searchData?.search
          ? searchData?.search
          : getVCFilterDataSuccess?.data?.search,
        searchData?.new_status
          ? searchData?.new_status
          : getVCFilterDataSuccess?.data?.status,
        searchData?.specialty
          ? searchData?.specialty
          : getVCFilterDataSuccess?.data?.specialty,
        searchData?.postcode
          ? searchData?.postcode
          : getVCFilterDataSuccess?.data?.postcode,
        searchData?.consultants
          ? searchData?.consultants
          : getVCFilterDataSuccess?.data?.consultants,
        order,
        orderBy,
        searchData?.proximity
          ? searchData?.proximity
          : getVCFilterDataSuccess?.data?.proximity,
        searchData?.latitude
          ? searchData?.latitude
          : getVCFilterDataSuccess?.data?.latitude,
        searchData?.longitude
          ? searchData?.longitude
          : getVCFilterDataSuccess?.data?.longitude,
        searchData?.start_date
        ? moment(searchData?.start_date).format('YYYY-MM-DD')
        : getVCFilterDataSuccess?.data?.start_date,
        searchData?.end_date
        ? moment(searchData?.end_date).format('YYYY-MM-DD')
        : getVCFilterDataSuccess?.data?.end_date
      );
    }
  }, [getVCFilterDataSuccess?.data, order, orderBy]);
console.log("speciality",speciality);
console.log("staff",staff);
  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        ""
      )}
      <Paper className={`${classes.root} mb-6`}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center", width: "24%" }}>
            <SearchIcon
              className={classes.searchIcondet}
              onClick={handleClickSearch}
            />
            <div className={classes.search}>
              <InputBase
                name="search"
                placeholder="Search…"
                onChange={handleSearchChange}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
            </div>
          </Box>
          <Box display="flex" alignItems="end">
            <Tooltip title="Filters">
              <Button onClick={handleMenu}>
                <FilterListIcon />
              </Button>
            </Tooltip>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              open={openMenu}
              onClose={handleMenuClose}
              className={classes.filterBookingSearch}
            >
              <Box>
                <div className={classes.filterBox}>
                  <Box className={classes.radioGroup}>
                    <Grid
                      container
                      style={{
                        gap: "5px",
                        flexWrap: "nowrap",
                        justifyContent: "space-between",
                        alignItems:"flex-end"
                      }}
                    >
                      <Grid className="row" md={6}>
                        <Box
                          mt={3}
                          mb={3}
                          display="flex"
                          alignItems="center"
                          sx={{ marginTop: 0, marginBottom: "0px" }}
                        >
                          <Grid
                            container
                            style={{ gap: "10px", flexWrap: "nowrap" }}
                          >
                            <Grid md={4}>
                              <FormControl fullWidth variant="outlined">
                                <InputLabel>Status</InputLabel>
                                <Select
                                  label="Status"
                                  name="new_status"
                                  onChange={(e) =>
                                    setSearchData({
                                      ...searchData,
                                      [e.target.name]: e.target.value,
                                    })
                                  }
                                  value={
                                    searchData?.new_status
                                      ? searchData?.new_status
                                      : getVCFilterDataSuccess?.data?.new_status
                                  }
                                >
                                  <MenuItem value="">Select Status</MenuItem>
                                  <MenuItem value="Active">Active</MenuItem>
                                  <MenuItem value="Not active">
                                  Not active
                                  </MenuItem>
                                  <MenuItem value="Pre reg">Pre reg</MenuItem>
                                  <MenuItem value="New sign up">New sign up</MenuItem>
                                  <MenuItem value="Archive">Archive</MenuItem>
                                  <MenuItem value="Do not use">Do not use</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid md={4}>
                              <TextField
                                id="address_line_1"
                                label="Location (Post Code)"
                                variant="outlined"
                                type="text"
                                name="postcode"
                                className="form-field"
                                value={searchData?.postcode}
                                inputRef={address1Ref}
                                onChange={handleChange}
                                sx={{ width: "100%" }}
                              />
                            </Grid>
                            <Grid md={4}>
                              {" "}
                              <TextField
                                id="proximity"
                                label="Proximity"
                                variant="outlined"
                                type="text"
                                name="proximity"
                                className="form-field"
                                value={searchData?.proximity}
                                onChange={handleChange}
                                sx={{ width: "100%" }}
                              />
                            </Grid>
                          </Grid>
                        </Box>

                        <Box mt={2} display="flex" alignItems="center">
                          <Box
                            display="flex"
                            alignItems="center"
                            flexGrow={1}
                            style={{ gap: "5px" }}
                          >
                            <Grid md={6}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel>Consultants</InputLabel>
                              <Select
                                label="Consultants"
                                name="consultants"
                                onChange={(e) =>
                                  setSearchData({
                                    ...searchData,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                value={
                                  searchData?.consultants
                                    // ? searchData?.consultants
                                    // : getVCFilterDataSuccess?.data?.consultants
                                }
                              >
                                <MenuItem value="">Select Consultants</MenuItem>
                                {staff?.data !== undefined &&
                                  staff?.data &&
                                  staff?.data.map((trustList, index) => {
                                    return (
                                      <MenuItem
                                        value={trustList?.id}
                                        key={index}
                                      >
                                        {trustList?.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                            </Grid>
                            <Grid md={6}>
                            <FormControl fullWidth variant="outlined">
                              <InputLabel>Specialty</InputLabel>
                              <Select
                                label="Specialty"
                                name="specialty"
                                onChange={(e) =>
                                  setSearchData({
                                    ...searchData,
                                    [e.target.name]: e.target.value,
                                  })
                                }
                                value={
                                  searchData?.specialty
                                    ? searchData?.specialty
                                    : getVCFilterDataSuccess?.data?.specialty
                                }
                              >
                                <MenuItem value="">Select Specialty</MenuItem>
                                {speciality?.data &&
                                  speciality?.data.map((items, index) => {
                                    return (
                                      <MenuItem value={items?.id} key={index}>
                                        {items?.speciality_name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                            </Grid>
                          </Box>
                        </Box>
                      </Grid>
                      {/* <Date container start > */}
                          <Grid item xs={12} md="auto">
                            <p>Last worked date</p>
                            <Box
                              display="flex"
                              flexDirection="column"
                              style={{ gap:"15px"}} // Vertical spacing between the date pickers
                            >
                              {/* Start Date Picker */}
                              <Box>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  localeText={{ start: "Check-in", end: "Check-out" }}
                                >
                                  <DatePicker
                                    label="Date From"
                                    inputFormat="dd/MM/yyyy"
                                    name="start_date"
                                    value={searchData?.start_date
                                      ? searchData?.start_date
                                      : getVCFilterDataSuccess?.data?.start_date || null}
                                    onChange={(newValue) => setSearchData({
                                      ...searchData,
                                      start_date:newValue,
                                    })}
                                    firstDayOfWeek={1}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                              {/* End Date Picker */}
                              <Box>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  localeText={{ start: "Check-in", end: "Check-out" }}
                                >
                                  <DatePicker
                                    label="Date To"
                                    inputFormat="dd/MM/yyyy"
                                    name="end_date"
                                    value={searchData?.end_date
                                      ? searchData?.end_date
                                      : getVCFilterDataSuccess?.data?.end_date || null}
                                    onChange={(newValue) => setSearchData({
                                      ...searchData,
                                      end_date:newValue,
                                    })}
                                    firstDayOfWeek={1}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="outlined"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Box>
                          </Grid>
                        {/* <Date end> */}
                  
                      <Grid className="row" md={2}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <div style={{ marginBottom: "12px" }}>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={handleDownload}
                            >
                              Download
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: "12px",
                            }}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              className={classes.searchIcondet}
                              onClick={handleClickSave}
                            >
                              Save
                            </Button>
                          </div>
                          <div>
                            <Button
                              fullWidth
                              variant="contained"
                              color="secondary"
                              onClick={handleClickSearch}
                            >
                              Search
                            </Button>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Box>
            </Menu>
            {staffDetail !== "Finance" && (
              <div className="ml-5">
                <Link to={`${match.url}/create`} className="btn btn-secondary">
                  <AddIcon className="mr-2" />
                  Add Candidate
                </Link>
              </div>
            )}
          </Box>
        </Box>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) => {
                handleRequestSort(
                  property,
                  setOrder,
                  setOrderBy,
                  orderBy,
                  order
                );
              }}
              rowCount={getSigneeItem?.data?.data?.length}
              headCells={headCells[0]}
            />

            <TableBody>
              {getSigneeItem?.data?.data &&
                getSigneeItem?.data?.data.map((list, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell scope="row">
                        {getSigneeItem?.data?.from + index}
                      </TableCell>
                      <TableCell align="left">{list.candidate_name}</TableCell>
                      <TableCell align="left">{list.consultant}</TableCell>

                      <TableCell
                        align="left"
                        style={{ color: list.id_badge_ex_date }}
                      >
                        {list.id_badge_exp_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: list.accessni_ex_date }}
                      >
                        {list.accessni_exp_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: list.db_expire_date }}
                      >
                        {list.dbs_expire_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: list.ft_expire_date }}
                      >
                        {list.fit_expire_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ width: 140, color: list.pass_expire_date }}
                      >
                        {list.passport_expire_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: list.nm_expire_date }}
                      >
                        {list.nmc_expire_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: list.mand_expire_date }}
                      >
                        {list.mandotoryt_expire_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: list.ref_1_expire_date }}
                      >
                        {list.ref1_expire_date}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ color: list.ref_2_expire_date }}
                      >
                        {list.ref2_expire_date}
                      </TableCell>
                      <TableCell align="left">{list.distance ?? "-"}</TableCell>
                      <TableCell align="left">{moment(list.last_shift_date).isValid() ? moment(list.last_shift_date).format("DD-MM-YYYY") : "-"}</TableCell>
                      <TableCell align="right" style={{ width: 140 }}>
                        <Link
                          to="#"
                          className="btn btn-secondary"
                          onClick={(e) => onhandlClick(list.id)}
                        >
                          View
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {getSigneeItem?.data?.data.length === 0 && (
                <TableRow>
                  <TableCell align="center" colSpan="6" scope="">
                    {" "}
                    Sorry no record available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className="mt-5" display="flex" justifyContent="flex-end">
          <Pagination
            onChange={handleChangePage}
            page={page}
            count={getSigneeItem?.data?.last_page}
            showFirstButton
            showLastButton
          />
        </Box>
      </Paper>
    </>
  );
};

export default ViewSignee;
