export const FETCH_CANDIDATES_REQUEST = "FETCH_CANDIDATES_REQUEST";
export const FETCH_CANDIDATES_SUCCESS = "FETCH_CANDIDATES_SUCCESS";
export const FETCH_CANDIDATES_FAILURE = "FETCH_CANDIDATES_FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const GET_ORGANIZATION_REQUEST = "GET_ORGANIZATION_REQUEST";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_ERROR = "GET_ORGANIZATION_ERROR";

export const CREATE_ORGANIZATION_REQUEST = "CREATE_ORGANIZATION_REQUEST";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_ERROR = "CREATE_ORGANIZATION_ERROR";

export const UPDATE_ORGANIZATION_REQUEST = "UPDATE_ORGANIZATION_REQUEST";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_ERROR = "UPDATE_ORGANIZATION_ERROR";

export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const FORGOT_REQUEST = "FORGOT_REQUEST";
export const FORGOT_ERROR = "FORGOT_ERROR";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export const CHANGE_PASS_SUCCESS = "CHANGE_PASS_SUCCESS";
export const CHANGE_PASS_REQUEST = "CHANGE_PASS_REQUEST";
export const CHANGE_PASS_ERROR = "CHANGE_PASS_ERROR";

export const CHANGE_ORG_PASS_SUCCESS = "CHANGE_ORG_PASS_SUCCESS";
export const CHANGE_ORG_PASS_REQUEST = "CHANGE_ORG_PASS_REQUEST";
export const CHANGE_ORG_PASS_ERROR = "CHANGE_ORG_PASS_ERROR";

export const GET_ORG_PROFILE_REQUEST = "GET_ORG_PROFILE_REQUEST";
export const GET_ORG_PROFILE_SUCCESS = "GET_ORG_PROFILE_SUCCESS";
export const GET_ORG_PROFILE_ERROR = "GET_ORG_PROFILE_ERROR";

export const UPDATE_ORG_PROFILE_REQUEST = "UPDATE_ORG_PROFILE_REQUEST";
export const UPDATE_ORG_PROFILE_SUCCESS = "UPDATE_ORG_PROFILE_SUCCESS";
export const UPDATE_ORG_PROFILE_ERROR = "UPDATE_ORG_PROFILE_ERROR";

export const CREATE_ROLE_REQUEST = "CREATE_ROLE_REQUEST";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_ERROR = "CREATE_ROLE_ERROR";

export const GET_ROLE_REQUEST = "GET_ROLE_REQUEST";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_ERROR = "GET_ROLE_ERROR";

export const DELETE_ROLE_REQUEST = "DELETE_ROLE_REQUEST";
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS";
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR";

export const GET_SPECIALITIES_REQUEST = "GET_SPECIALITIES_REQUEST";
export const GET_SPECIALITIES_SUCCESS = "GET_SPECIALITIES_SUCCESS";
export const GET_SPECIALITIES_ERROR = "GET_SPECIALITIES_ERROR";

export const CREATE_SPECIALITIES_REQUEST = "CREATE_SPECIALITIES_REQUEST";
export const CREATE_SPECIALITIES_SUCCESS = "CREATE_SPECIALITIES_SUCCESS";
export const CREATE_SPECIALITIES_ERROR = "CREATE_SPECIALITIES_ERROR";

export const SPECIALITIES_SHIFT_REQUEST = "SPECIALITIES_SHIFT_REQUEST";

export const UPDATE_SPECIALITIES_REQUEST = "UPDATE_SPECIALITIES_REQUEST";
export const UPDATE_SPECIALITIES_SUCCESS = "UPDATE_SPECIALITIES_SUCCESS";
export const UPDATE_SPECIALITIES_ERROR = "UPDATE_SPECIALITIES_ERROR";

export const DELETE_SPECIALITIES_REQUEST = "DELETE_SPECIALITIES_REQUEST";
export const DELETE_SPECIALITIES_SUCCESS = "DELETE_SPECIALITIES_SUCCESS";
export const DELETE_SPECIALITIES_ERROR = "DELETE_SPECIALITIES_ERROR";

export const GET_STAFF_DETAIL_REQUEST = "GET_STAFF_DETAIL_REQUEST";
export const GET_COMPILANCE_OFFICER_DETAIL_REQUEST = "GET_COMPILANCE_OFFICER_DETAIL_REQUEST";
export const GET_STAFF_DETAIL_SUCCESS = "GET_STAFF_DETAIL_SUCCESS";
export const GET_STAFF_DETAIL_ERROR = "GET_STAFF_DETAIL_ERROR";
export const GET_CANDIDATE_LIST_REQUEST="GET_CANDIDATE_LIST_REQUEST";
export const GET_CANDIDATE_MUTIPLE_LIST_REQUEST="GET_CANDIDATE_MUTIPLE_LIST_REQUEST";

export const GET_STAFF_REQUEST = "GET_STAFF_REQUEST";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_ERROR = "GET_STAFF_ERROR";

export const CREATE_STAFF_REQUEST = "CREATE_STAFF_REQUEST";
export const CREATE_STAFF_SUCCESS = "CREATE_STAFF_SUCCESS";
export const CREATE_STAFF_ERROR = "CREATE_STAFF_ERROR";

export const UPDATE_STAFF_REQUEST = "UPDATE_STAFF_REQUEST";
export const UPDATE_STAFF_SUCCESS = "UPDATE_STAFF_SUCCESS";
export const UPDATE_STAFF_ERROR = "UPDATE_STAFF_ERROR";

export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_ERROR = "DELETE_STAFF_ERROR";

export const GET_SINGAL_TRUST_REQUEST = "GET_SINGAL_TRUST_REQUEST";
export const GET_ALL_TRUST_REQUEST = "GET_ALL_TRUST_REQUEST";

export const GET_TRUST_REQUEST = "GET_TRUST_REQUEST";
export const GET_TRUST_SUCCESS = "GET_TRUST_SUCCESS";
export const GET_TRUST_ERROR = "GET_TRUST_ERROR";

export const CREATE_TRUST_REQUEST = "CREATE_TRUST_REQUEST";
export const CREATE_TRUST_SUCCESS = "CREATE_TRUST_SUCCESS";
export const CREATE_TRUST_ERROR = "CREATE_TRUST_ERROR";

export const UPDATE_TRUST_REQUEST = "UPDATE_TRUST_REQUEST";
export const UPDATE_TRUST_SUCCESS = "UPDATE_TRUST_SUCCESS";
export const UPDATE_TRUST_ERROR = "UPDATE_TRUST_ERROR";

export const DELETE_TRUST_REQUEST = "DELETE_TRUST_REQUEST";
export const DELETE_TRUST_SUCCESS = "DELETE_TRUST_SUCCESS";
export const DELETE_TRUST_ERROR = "DELETE_TRUST_ERROR";

export const GET_BOOKING_REQUEST = "GET_BOOKING_REQUEST";
export const GET_BOOKING_SUCCESS = "GET_BOOKING_SUCCESS";
export const GET_BOOKING_ERROR = "GET_BOOKING_ERROR";

export const GET_BOOKING_INVOICE_REQUEST = "GET_BOOKING_INVOICE_REQUEST";
export const GET_BOOKING_INVOICE_SUCCESS = "GET_BOOKING_INVOICE_SUCCESS";
export const GET_BOOKING_INVOICE_ERROR = "GET_BOOKING_INVOICE_ERROR";

export const GET_SINGLE_BOOKING = "GET_SINGLE_BOOKING";
export const GET_BOOKING_SPECIALITY = "GET_BOOKING_SPECIALITY";
export const GET_BOOKING_TRUST = "GET_BOOKING_TRUST";
export const GET_HOSPITAL_LIST = "GET_HOSPITAL_LIST";
export const GET_WARD_BY_HOSPITAL = "GET_WARD_BY_HOSPITAL";

export const CREATE_BOOKING_REQUEST = "CREATE_BOOKING_REQUEST";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_ERROR = "CREATE_BOOKING_ERROR";

export const CREATE_FILTER_REQUEST = "CREATE_FILTER_REQUEST";
export const DELETE_FILTER_REQUEST = "DELETE_FILTER_REQUEST";
export const GET_FILTER_DATA_REQUEST = "GET_FILTER_DATA_REQUEST";
export const GET_FILTER_DATA_SUCCESS = "GET_FILTER_DATA_SUCCESS";
export const GET_IS_FILTER_DATA_SUCCESS = "GET_IS_FILTER_DATA_SUCCESS";
export const GET_VC_FILTER_DATA_REQUEST = "GET_VC_FILTER_DATA_REQUEST";
export const GET_VC_FILTER_DATA_SUCCESS = "GET_VC_FILTER_DATA_SUCCESS";
export const EMPTY_VC_FILTER_DATA = "EMPTY_VC_FILTER_DATA";
export const CREATE_FILTER_SUCCESS = "CREATE_FILTER_SUCCESS";


export const UPDATE_BOOKING_REQUEST = "UPDATE_BOOKING_REQUEST";
export const UPDATE_BOOKING_SUCCESS = "UPDATE_BOOKING_SUCCESS";
export const UPDATE_BOOKING_ERROR = "UPDATE_BOOKING_ERROR";

export const DELETE_BOOKING_REQUEST = "DELETE_BOOKING_REQUEST";
export const DELETE_BOOKING_SUCCESS = "DELETE_BOOKING_SUCCESS";
export const DELETE_BOOKING_ERROR = "DELETE_BOOKING_ERROR";

export const GET_SIGNEE_REQUETS = "GET_SIGNEE_REQUETS";
export const GET_SIGNEE_SUCCESS = "GET_SIGNEE_SUCCESS";
export const GET_SIGNEE_ERROR = "GET_SIGNEE_ERROR";

export const GET_COMPLIANCE_REQUETS = "GET_COMPLIANCE_REQUETS";
export const GET_COMPLIANCE_SUCCESS = "GET_COMPLIANCE_SUCCESS";
export const GET_COMPLIANCE_ERROR = "GET_COMPLIANCE_ERROR";

export const CREATE_SIGNEE_REQUETS = "CREATE_SIGNEE_REQUETS";
export const CREATE_SIGNEE_SUCCESS = "CREATE_SIGNEE_SUCCESS";
export const CREATE_SIGNEE_ERROR = "CREATE_SIGNEE_ERROR";

export const UPDATE_SIGNEE_REQUETS = "UPDATE_SIGNEE_REQUETS";
export const UPDATE_SIGNEE_SUCCESS = "UPDATE_SIGNEE_SUCCESS";
export const UPDATE_SIGNEE_ERROR = "UPDATE_SIGNEE_ERROR";

export const GET_CANDIDATE_REFERRED_FROM_SUCCESS =
  "GET_CANDIDATE_REFERRED_FROM_SUCCESS";

export const GET_SINGLE_SIGNEE_REQUETS = "GET_SINGLE_SIGNEE_REQUETS";
export const GET_SINGLE_SIGNEE_SUCCESS = "GET_SINGLE_SIGNEE_SUCCESS";
export const GET_SINGLE_SIGNEE_ERROR = "GET_SINGLE_SIGNEE_ERROR";

export const DELETE_SIGNEE_REQUETS = "DELETE_SIGNEE_REQUETS";
export const DELETE_SIGNEE_SUCCESS = "DELETE_SIGNEE_SUCCESS";
export const DELETE_SIGNEE_ERROR = "DELETE_SIGNEE_ERROR";

export const DOWNLOAD_CHECKLIST_REQUETS = "DOWNLOAD_CHECKLIST_REQUETS";
export const DOWNLOAD_CHECKLIST_SUCCESS = "DOWNLOAD_CHECKLIST_SUCCESS";
export const DOWNLOAD_CHECKLIST_ERROR = "DOWNLOAD_CHECKLIST_ERROR";

// ------------------

export const CHANGE_STAFF_PASS_SUCCESS = "CHANGE_STAFF_PASS_SUCCESS";
export const CHANGE_STAFF_PASS_REQUEST = "CHANGE_STAFF_PASS_REQUEST";
export const CHANGE_STAFF_PASS_ERROR = "CHANGE_STAFF_PASS_ERROR";

export const GET_STAFF_PROFILE_REQUEST = "GET_STAFF_PROFILE_REQUEST";
export const GET_STAFF_PROFILE_SUCCESS = "GET_STAFF_PROFILE_SUCCESS";
export const GET_STAFF_PROFILE_ERROR = "GET_STAFF_PROFILE_ERROR";

export const UPDATE_STAFF_PROFILE_REQUEST = "UPDATE_STAFF_PROFILE_REQUEST";
export const UPDATE_STAFF_PROFILE_SUCCESS = "UPDATE_STAFF_PROFILE_SUCCESS";
export const UPDATE_STAFF_PROFILE_ERROR = "UPDATE_STAFF_PROFILE_ERROR";

export const CONFIRM_BOOKING_REQUEST = "CONFIRM_BOOKING_REQUEST";
export const CONFIRM_BOOKING_SUCCESS = "CONFIRM_BOOKING_SUCCESS";
export const CONFIRM_BOOKING_ERROR = "CONFIRM_BOOKING_ERROR";

export const CHANGE_SHIFT_STATUS_REQUEST = "CHANGE_SHIFT_STATUS_REQUEST";
export const CHANGE_SHIFT_STATUS_SUCCESS = "CHANGE_SHIFT_STATUS_SUCCESS";
export const CHANGE_SHIFT_STATUS_ERROR = "CHANGE_SHIFT_STATUS_ERROR";

export const CHANGE_SIGNEE_PRO_STATUS_REQUEST =
  "CHANGE_SIGNEE_PRO_STATUS_REQUEST";
export const CHANGE_SIGNEE_PRO_STATUS_SUCCESS =
  "CHANGE_SIGNEE_PRO_STATUS_SUCCESS";
export const CHANGE_SIGNEE_PRO_STATUS_ERROR = "CHANGE_SIGNEE_PRO_STATUS_ERROR";

export const ENABLED_DISABLED_CANDIDATE_SUCCESS = "ENABLED_DISABLED_CANDIDATE_SUCCESS";
export const ENABLED_DISABLED_CANDIDATE_ERROR = "ENABLED_DISABLED_CANDIDATE_ERROR";
export const ENABLED_DISABLED_CANDIDATE_REQUEST ="ENABLED_DISABLED_CANDIDATE_REQUEST";

export const CHANGE_SIGNEE_COMP_STATUS_REQUEST =
  "CHANGE_SIGNEE_COMP_STATUS_REQUEST";
export const CHANGE_SIGNEE_COMP_STATUS_SUCCESS =
  "CHANGE_SIGNEE_COMP_STATUS_SUCCESS";
export const CHANGE_SIGNEE_COMP_STATUS_ERROR =
  "CHANGE_SIGNEE_COMP_STATUS_ERROR";

export const CHANGE_DOC_STATUS_REQUEST = "CHANGE_DOC_STATUS_REQUEST";
export const CHANGE_DOC_STATUS_SUCCESS = "CHANGE_DOC_STATUS_SUCCESS";
export const CHANGE_DOC_STATUS_ERROR = "CHANGE_DOC_STATUS_ERROR";

export const USER_INVITATION_REQUEST = "USER_INVITATION_REQUEST";
export const USER_INVITATION_SUCCESS = "USER_INVITATION_SUCCESS";
export const USER_INVITATION_ERROR = "USER_INVITATION_ERROR";

export const PUT_NOTIFY = "PUT_NOTIFY";

export const ADD_NEW_NOTIFICATION_FAIL = "ADD_NEW_NOTIFICATION_FAIL";
export const ADD_NEW_NOTIFICATION_SUCCESS = "ADD_NEW_NOTIFICATION_SUCCESS";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";

export const ADD_NEW_LOADING_REQUEST = "ADD_NEW_LOADING_REQUEST";
export const ADD_NEW_LOADING_SUCCESS = "ADD_NEW_LOADING_SUCCESS";
export const ADD_NEW_LOADING_FAIL = "ADD_NEW_LOADING_FAIL";

export const CHANGE_PAYMENT_STATUS_REQUEST = "CHANGE_PAYMENT_STATUS_REQUEST";
export const CHANGE_PAYMENT_STATUS_SUCCESS = "CHANGE_PAYMENT_STATUS_SUCCESS";
export const CHANGE_PAYMENT_STATUS_ERROR = "CHANGE_PAYMENT_STATUS_ERROR";

export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";

export const GET_DASHBOARD_REQUEST = "GET_DASHBOARD_REQUEST";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_ERROR = "GET_DASHBOARD_ERROR";

export const CREATE_PAYMENT_ERROR = "CREATE_PAYMENT_ERROR";
export const CREATE_PAYMENT_REQUEST = "CREATE_PAYMENT_REQUEST";
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";

export const GET_PLAN_REQUEST = "GET_PLAN_REQUEST";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_ERROR = "GET_PLAN_ERROR";

export const UPDATE_PLAN_REQUEST = "UPDATE_PLAN_REQUEST";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_ERROR = "UPDATE_PLAN_ERROR";

export const CREATE_HOLIDAY_REQUEST = "CREATE_HOLIDAY_REQUEST";
export const CREATE_HOLIDAY_SUCCESS = "CREATE_HOLIDAY_SUCCESS";
export const CREATE_HOLIDAY_ERROR = "CREATE_HOLIDAY_ERROR";

export const GET_HOLIDAY_REQUEST = "GET_HOLIDAY_REQUEST";
export const GET_HOLIDAY_SUCCESS = "GET_HOLIDAY_SUCCESS";
export const GET_HOLIDAY_ERROR = "GET_HOLIDAY_ERROR";

export const GET_HOLIDAY_BY_ID_REQUEST = "GET_HOLIDAY_BY_ID_REQUEST";

export const DELETE_HOLIDAY_REQUEST = "DELETE_HOLIDAY_REQUEST";
export const DELETE_HOLIDAY_SUCCESS = "DELETE_HOLIDAY_SUCCESS";
export const DELETE_HOLIDAY_ERROR = "DELETE_HOLIDAY_ERROR";

export const UPDATE_HOLIDAY_REQUEST = "UPDATE_HOLIDAY_REQUEST";
export const UPDATE_HOLIDAY_SUCCESS = "UPDATE_HOLIDAY_SUCCESS";
export const UPDATE_HOLIDAY_ERROR = "UPDATE_HOLIDAY_ERROR";

export const GET_CONTACT_EVENT_REQUETS = "GET_CONTACT_EVENT_REQUETS";
export const GET_CONTACT_EVENT_SUCCESS = "GET_CONTACT_EVENT_SUCCESS";
export const GET_CONTACT_EVENT_ERROR = "GET_CONTACT_EVENT_ERROR";

export const ADD_CONTACT_EVENT_REQUETS = "ADD_CONTACT_EVENT_REQUETS";
export const ADD_CONTACT_EVENT_SUCCESS = "ADD_CONTACT_EVENT_SUCCESS";
export const ADD_CONTACT_EVENT_ERROR = "ADD_CONTACT_EVENT_ERROR";

export const CREATE_PAYMENT_SCHEME_ERROR = "CREATE_PAYMENT_SCHEME_ERROR";
export const CREATE_PAYMENT_SCHEME_REQUEST = "CREATE_PAYMENT_SCHEME_REQUEST";
export const CREATE_PAYMENT_SCHEME_SUCCESS = "CREATE_PAYMENT_SCHEME_SUCCESS";
export const DELETE_PAYMENT_SCHEME_ERROR = "DELETE_PAYMENT_SCHEME_ERROR";
export const DELETE_PAYMENT_SCHEME_REQUEST = "DELETE_PAYMENT_SCHEME_REQUEST";
export const DELETE_PAYMENT_SCHEME_SUCCESS = "DELETE_PAYMENT_SCHEME_SUCCESS";
export const GET_PAYMENT_SCHEME_ERROR = "GET_PAYMENT_SCHEME_ERROR";
export const GET_PAYMENT_SCHEME_REQUEST = "GET_PAYMENT_SCHEME_REQUEST";
export const GET_PAYMENT_SCHEME_SUCCESS = "GET_PAYMENT_SCHEME_SUCCESS";
export const UPDATE_PAYMENT_SCHEME_ERROR = "UPDATE_PAYMENT_SCHEME_ERROR";
export const UPDATE_PAYMENT_SCHEME_REQUEST = "UPDATE_PAYMENT_SCHEME_REQUEST";
export const UPDATE_PAYMENT_SCHEME_SUCCESS = "UPDATE_PAYMENT_SCHEME_SUCCESS";

export const GET_UMBRELLA_DETAIL_REQUEST = "GET_UMBRELLA_DETAIL_REQUEST";

export const GET_UMBRELLA_REQUEST = "GET_UMBRELLA_REQUEST";
export const GET_UMBRELLA_SUCCESS = "GET_UMBRELLA_SUCCESS";
export const GET_UMBRELLA_ERROR = "GET_UMBRELLA_ERROR";

export const CREATE_UMBRELLA_REQUEST = "CREATE_UMBRELLA_REQUEST";
export const CREATE_UMBRELLA_SUCCESS = "CREATE_UMBRELLA_SUCCESS";
export const CREATE_UMBRELLA_ERROR = "CREATE_UMBRELLA_ERROR";

export const UPDATE_UMBRELLA_REQUEST = "UPDATE_UMBRELLA_REQUEST";
export const UPDATE_UMBRELLA_SUCCESS = "UPDATE_UMBRELLA_SUCCESS";
export const UPDATE_UMBRELLA_ERROR = "UPDATE_UMBRELLA_ERROR";

export const DELETE_UMBRELLA_REQUEST = "DELETE_UMBRELLA_REQUEST";
export const DELETE_UMBRELLA_SUCCESS = "DELETE_UMBRELLA_SUCCESS";
export const DELETE_UMBRELLA_ERROR = "DELETE_UMBRELLA_ERROR";

export const RESCHEDULE_BOOKING_REQUEST = "RESCHEDULE_BOOKING_REQUEST";
export const RESCHEDULE_BOOKING_ERROR = "RESCHEDULE_BOOKING_ERROR";
export const RESCHEDULE_BOOKING_SUCCESS = "RESCHEDULE_BOOKING_SUCCESS";

export const DELETE_DOCUMENT_REQUETS = "DELETE_DOCUMENT_REQUETS";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERROR = "DELETE_DOCUMENT_ERROR";

export const ADD_SIGNEE_REMARK_REQUETS = "ADD_SIGNEE_REMARK_REQUETS";
export const ADD_SIGNEE_REMARK_SUCCESS = "ADD_SIGNEE_REMARK_SUCCESS";
export const ADD_SIGNEE_REMARK_ERROR = "ADD_SIGNEE_REMARK_ERROR";

export const ADD_DOC_EXPIRE_DATE_REQUETS = "ADD_DOC_EXPIRE_DATE_REQUETS";
export const ADD_DOC_EXPIRE_DATE_SUCCESS = "ADD_DOC_EXPIRE_DATE_SUCCESS";
export const ADD_DOC_EXPIRE_DATE_ERROR = "ADD_DOC_EXPIRE_DATE_ERROR";

export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';
export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const UPDATE_DOC_TYPES_SUCCESS = 'UPDATE_DOC_TYPES_SUCCESS';
export const UPDATE_DOC_TYPES_ERROR = 'UPDATE_DOC_TYPES_ERROR';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';
export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_DOC_TYPES_REQUEST = 'UPDATE_DOC_TYPES_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';
export const GET_CATEGORY_BY_ID_REQUEST = 'GET_CATEGORY_BY_ID_REQUEST';
export const GET_DOC_BY_CATEGORY_ID_REQUEST = 'GET_DOC_BY_CATEGORY_ID_REQUEST';

export const CREATE_SUB_CATEGORY_ERROR = 'CREATE_SUB_CATEGORY_ERROR';
export const CREATE_SUB_CATEGORY_REQUEST = 'CREATE_SUB_CATEGORY_REQUEST';
export const CREATE_SUB_CATEGORY_SUCCESS = 'CREATE_SUB_CATEGORY_SUCCESS';
export const DELETE_SUB_CATEGORY_ERROR = 'DELETE_SUB_CATEGORY_ERROR';
export const DELETE_SUB_CATEGORY_REQUEST = 'DELETE_SUB_CATEGORY_REQUEST';
export const DELETE_SUB_CATEGORY_SUCCESS = 'DELETE_SUB_CATEGORY_SUCCESS';
export const HIDE_SUB_CATEGORY_ERROR = 'HIDE_SUB_CATEGORY_ERROR';
export const HIDE_SUB_CATEGORY_REQUEST = 'HIDE_SUB_CATEGORY_REQUEST';
export const HIDE_SUB_CATEGORY_SUCCESS = 'HIDE_SUB_CATEGORY_SUCCESS';
export const GET_SUB_CATEGORY_ERROR = 'GET_SUB_CATEGORY_ERROR';
export const GET_SUB_CATEGORY_REQUEST = 'GET_SUB_CATEGORY_REQUEST';
export const GET_SUB_CATEGORY_SUCCESS = 'GET_SUB_CATEGORY_SUCCESS';
export const UPDATE_SUB_CATEGORY_REQUEST = 'UPDATE_SUB_CATEGORY_REQUEST';
export const UPDATE_SUB_CATEGORY_SUCCESS = 'UPDATE_SUB_CATEGORY_SUCCESS';
export const UPDATE_SUB_CATEGORY_ERROR = 'UPDATE_SUB_CATEGORY_ERROR';
export const GET_SUB_CATEGORY_BY_ID_REQUEST = 'GET_SUB_CATEGORY_BY_ID_REQUEST';

export const DELETE_TIMESHEET_REQUEST = "DELETE_TIMESHEET_REQUEST";
export const DELETE_TIMESHEET_SUCCESS = "DELETE_TIMESHEET_SUCCESS";
export const DELETE_TIMESHEET_ERROR = "DELETE_TIMESHEET_ERROR";

export const GET_SYSTEM_LOGS_ERROR = "GET_SYSTEM_LOGS_ERROR";
export const GET_SYSTEM_LOGS_REQUEST = "GET_SYSTEM_LOGS_REQUEST";
export const GET_SYSTEM_LOGS_SUCCESS = "GET_SYSTEM_LOGS_SUCCESS";
export const GET_MODULES = "GET_MODULES";
export const GET_OPERATIONS = "GET_OPERATIONS";

export const STATUS_STAFF_ERROR = "STATUS_STAFF_ERROR";
export const STATUS_STAFF_REQUEST = "STATUS_STAFF_REQUEST";
export const STATUS_STAFF_SUCCESS = "STATUS_STAFF_SUCCESS";

export const CANCEL_INVOICE_REQUEST = "CANCEL_INVOICE_REQUEST";
export const CANCEL_INVOICE_SUCCESS = "CANCEL_INVOICE_SUCCESS";
export const CANCEL_INVOICE_ERROR = "CANCEL_INVOICE_ERROR";

export const CREATE_NOTIFICATION_PREF_ERROR = 'CREATE_NOTIFICATION_PREF_ERROR';
export const CREATE_NOTIFICATION_PREF_REQUEST = 'CREATE_NOTIFICATION_PREF_REQUEST';
export const CREATE_NOTIFICATION_PREF_SUCCESS = 'CREATE_NOTIFICATION_PREF_SUCCESS';
  
export const DELETE_NOTIFICATION_PREF_ERROR = 'DELETE_NOTIFICATION_PREF_ERROR';
export const DELETE_NOTIFICATION_PREF_REQUEST = 'DELETE_NOTIFICATION_PREF_REQUEST';
export const DELETE_NOTIFICATION_PREF_SUCCESS = 'DELETE_NOTIFICATION_PREF_SUCCESS';
  
export const GET_NOTIFICATION_PREF_ERROR = 'GET_NOTIFICATION_PREF_ERROR';
export const GET_NOTIFICATION_PREF_REQUEST = 'GET_NOTIFICATION_PREF_REQUEST';
export const GET_NOTIFICATION_PREF_SUCCESS = 'GET_NOTIFICATION_PREF_SUCCESS';
  
export const UPDATE_NOTIFICATION_PREF_REQUEST = 'UPDATE_NOTIFICATION_PREF_REQUEST';
export const UPDATE_NOTIFICATION_PREF_SUCCESS = 'UPDATE_NOTIFICATION_PREF_SUCCESS';
export const UPDATE_NOTIFICATION_PREF_ERROR = 'UPDATE_NOTIFICATION_PREF_ERROR';
export const GET_NOTIFICATION_PREF_BY_ID_REQUEST = 'GET_NOTIFICATION_PREF_BY_ID_REQUEST';

export const CREATE_USER_NOTIFICATION_PREF_ERROR = 'CREATE_USER_NOTIFICATION_PREF_ERROR';
export const CREATE_USER_NOTIFICATION_PREF_REQUEST = 'CREATE_USER_NOTIFICATION_PREF_REQUEST';
export const CREATE_USER_NOTIFICATION_PREF_SUCCESS = 'CREATE_USER_NOTIFICATION_PREF_SUCCESS';
  
export const GET_USER_NOTIFICATION_PREF_ERROR = 'GET_USER_NOTIFICATION_PREF_ERROR';
export const GET_USER_NOTIFICATION_PREF_REQUEST = 'GET_USER_NOTIFICATION_PREF_REQUEST';
export const GET_USER_NOTIFICATION_PREF_SUCCESS = 'GET_USER_NOTIFICATION_PREF_SUCCESS';
export const GET_TEMP_TIMESHEETS = 'GET_TEMP_TIMESHEETS';

export const GET_TIMESHEET_REQUEST = "GET_TIMESHEET_REQUEST";
export const GET_TIMESHEET_SUCCESS = "GET_TIMESHEET_SUCCESS";
export const GET_TIMESHEET_ERROR = "GET_TIMESHEET_ERROR";
export const DELETE_TEMP_TIMESHEET_ERROR ="DELETE_TEMP_TIMESHEET_ERROR";
export const DELETE_TEMP_TIMESHEET_REQUEST = "DELETE_TEMP_TIMESHEET_REQUEST";
export const DELETE_TEMP_TIMESHEET_SUCCESS = "DELETE_TEMP_TIMESHEET_SUCCESS";
export const GET_TEMP_TIMESHEET_BY_ID_REQUEST ="GET_TEMP_TIMESHEET_BY_ID_REQUEST";

export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";
export const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST";
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS";

export const CREATE_EXPENSE_REQUEST = "CREATE_EXPENSE_REQUEST";
export const CREATE_EXPENSE_SUCCESS = "CREATE_EXPENSE_SUCCESS";
export const CREATE_EXPENSE_ERROR = "CREATE_EXPENSE_ERROR";

export const GET_EXPENSE_REQUEST = "GET_EXPENSE_REQUEST";
export const GET_EXPENSE_SUCCESS = "GET_EXPENSE_SUCCESS";
export const GET_EXPENSE_ERROR = "GET_EXPENSE_ERROR";

