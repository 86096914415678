import axios from "axios";
import { apiClient } from "../../../config/apiClient";
import apiConfigs from "../../../config/config";
import history from "../../../utils/HistoryUtils";
import moment from 'moment'
import {
  CHANGE_PAYMENT_STATUS_ERROR,
  CHANGE_PAYMENT_STATUS_REQUEST,
  CHANGE_PAYMENT_STATUS_SUCCESS,
  CHANGE_SHIFT_STATUS_ERROR,
  CHANGE_SHIFT_STATUS_REQUEST,
  CHANGE_SHIFT_STATUS_SUCCESS,
  CONFIRM_BOOKING_ERROR,
  CONFIRM_BOOKING_REQUEST,
  CONFIRM_BOOKING_SUCCESS,
  CREATE_BOOKING_ERROR,
  CREATE_BOOKING_REQUEST,
  CREATE_BOOKING_SUCCESS,
  DELETE_BOOKING_ERROR,
  DELETE_BOOKING_REQUEST,
  DELETE_BOOKING_SUCCESS,
  GET_BOOKING_ERROR,
  GET_BOOKING_REQUEST,
  GET_BOOKING_INVOICE_ERROR,
  GET_BOOKING_INVOICE_REQUEST,
  GET_BOOKING_INVOICE_SUCCESS,
  GET_BOOKING_SPECIALITY,
  GET_BOOKING_SUCCESS,
  GET_BOOKING_TRUST,
  GET_HOSPITAL_LIST,
  GET_SINGLE_BOOKING,
  GET_WARD_BY_HOSPITAL,
  UPDATE_BOOKING_ERROR,
  UPDATE_BOOKING_REQUEST,
  UPDATE_BOOKING_SUCCESS,
  USER_INVITATION_ERROR,
  USER_INVITATION_REQUEST,
  USER_INVITATION_SUCCESS,
  RESCHEDULE_BOOKING_REQUEST,
  RESCHEDULE_BOOKING_SUCCESS,
  RESCHEDULE_BOOKING_ERROR,
  DELETE_TIMESHEET_REQUEST,
  DELETE_TIMESHEET_SUCCESS,
  DELETE_TIMESHEET_ERROR,
  CANCEL_INVOICE_REQUEST,
  CANCEL_INVOICE_SUCCESS,
  CANCEL_INVOICE_ERROR,
  GET_TEMP_TIMESHEETS,
  CREATE_FILTER_REQUEST,
  DELETE_FILTER_REQUEST,
  GET_FILTER_DATA_REQUEST,
  GET_FILTER_DATA_SUCCESS,
  GET_IS_FILTER_DATA_SUCCESS,
  CREATE_FILTER_SUCCESS,
  GET_EXPENSE_REQUEST,
  GET_EXPENSE_SUCCESS,
  GET_EXPENSE_ERROR,
  CREATE_EXPENSE_REQUEST,
  CREATE_EXPENSE_SUCCESS,
  CREATE_EXPENSE_ERROR,
} from "../actiontypes";
import { notificationFail, notificationSuccess } from "../notificationMsg";

export const getBooking = ({
  pageNo = 1,
  search = "",
  status = "CREATED",
  specialty = "",
  customer = "",
  candidate = "",
  site = "",
  ward = "",
  consultants = "",
  start_date = "",
  end_date = "",
  provisional_status="",
  type="",
  column_name="",
  page_limit=15
}) => {
  return async (dispatch) => {
    dispatch(confirmBookingFailure([]));
    dispatch(notificationFail(""));
    dispatch(getBookingRequest());


    console.log(start_date,"start-date-mayank");
    console.log(end_date,"end-date-mayank");
    const formattedStartDate = (start_date && start_date !== "Invalid date") ? moment(start_date).format('DD-MM-yyyy') : moment().subtract(7, 'days').format('DD-MM-yyyy');
    const formattedEndDate = (end_date && end_date !== "Invalid date") ? moment(end_date).format('DD-MM-yyyy') : moment().format('DD-MM-yyyy');

    await apiClient(true)
      .get(
        `api/organization/booking-by-status?status=${status}&search=${search}&page=${pageNo}&speciality=${specialty}&customer=${customer}&site=${site}&ward=${ward}&consultant=${consultants}&candidate=${candidate}&start_date=${formattedStartDate}&end_date=${formattedEndDate}&provisional_status=${provisional_status}&type=${type}&column_name=${column_name}&page_limit=${page_limit}`
      )
      .then((response) => {
        dispatch(getBookingSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getBookingSuccess(""));
        dispatch(getBookingError(error));
      });
  };
};

const getBookingRequest = () => {
  return {
    type: GET_BOOKING_REQUEST,
  };
};

const getBookingSuccess = (data) => {
  return {
    type: GET_BOOKING_SUCCESS,
    payload: data,
  };
};

const getBookingError = (error) => {
  return {
    type: GET_BOOKING_ERROR,
    payload: error,
  };
};
//----------------------------

export const getSingleBooking = (
  { booking_id = "", search = "", type = "", proximity="" },
  result
) => {
  return async (dispatch) => {
    dispatch(getSingleBookingRequest());
    await apiClient(true)
      .get(
        `api/organization/get-booking/${booking_id}?name=${search}&type=${type}&proximity=${proximity}`
      )
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getSingleBookingRequest = () => {
  return {
    type: GET_SINGLE_BOOKING,
  };
};

//----------------------------

export const getBookingSpeciality = (getTrustId, result) => {
  return async (dispatch) => {
    dispatch(getBookingSpecialityRequest());
    await apiClient(true)
      .get(`api/organization/get-booking-speciality?trustId=${getTrustId}`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getBookingSpecialityRequest = () => {
  return {
    type: GET_BOOKING_SPECIALITY,
  };
};

//-------------------------------
export const getBookingTrust = (result) => {
  return async (dispatch) => {
    dispatch(getBookingTrustRequest());
    await apiClient(true)
      .get(`api/organization/booking-trust`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getBookingTrustRequest = () => {
  return {
    type: GET_BOOKING_TRUST,
  };
};
// -------------------------------

export const getHospitalList = (getTrustId, result) => {
  return async (dispatch) => {
    dispatch(getHospitalListRequest());
    await apiClient(true)
      .get(`api/organization/get-hospitallist/${getTrustId}`)
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getHospitalListRequest = () => {
  return {
    type: GET_HOSPITAL_LIST,
  };
};
// -------------------------------

export const getWardByHospital = (hospitalId, getTrustId, result) => {
  return async (dispatch) => {
    dispatch(getWardByHospitalRequest());
    await apiClient(true)
      .get(
        `api/organization/get-ward-by-hospital?hospitalId=${hospitalId}&trustId=${getTrustId}`
      )
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getWardByHospitalRequest = () => {
  return {
    type: GET_WARD_BY_HOSPITAL,
  };
};

// -------------------------------

export const getTempTimesheets = (search,result) => {
  return async (dispatch) => {
    dispatch(getTempTimesheetsRequest());
    await apiClient(true)
      .get(
        `api/organization/get-temp-timesheet-list?search=${search}`
      )
      .then((response) => {
        result(response?.data);
      })
      .catch((error) => {
        result(error);
      });
  };
};

const getTempTimesheetsRequest = () => {
  return {
    type: GET_TEMP_TIMESHEETS,
  };
};
// -------------------------------

export const createBooking = (payload, addAnother) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createBookingRequest());
    await axios
      .post(`${apiConfigs.API_URL}api/organization/add-booking`, payload, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          if(payload.superAssignId !== ''){
            let payloads = {
            booking_id:data?.data?.id,
            signee_id: payload?.superAssignId ? payload?.superAssignId : '',
            status:"CONFIRMED"
          }
          dispatch(
            confirmBooking(payloads, (result) => {
              let payloadData = {
               booking_id:payloads?.booking_id,
               status:"CONFIRMED"
              }
              dispatch(changeShiftStatus(payloadData))
            })
          );
          }
          
          dispatch(createBookingSuccess(response?.data));
          
          if (addAnother === true) {
            setTimeout(() => {
               window.location.reload();
            }, 2000);
          } else {
           
            setTimeout(() => {
              history.goBack();
            }, 2000);
          }
        } else {
          data.message = (data?.message?.reference_id) ? data?.message?.reference_id : 'Something went wrong!';
          dispatch(createBookingError(data));
          window.location.reload();
        }
      })
      .catch((error) => {
        dispatch(createBookingError(error));
        window.location.reload();
      });
  };
};

const createBookingRequest = () => {
  return {
    type: CREATE_BOOKING_REQUEST,
  };
};

const createBookingSuccess = (data) => {
  return {
    type: CREATE_BOOKING_SUCCESS,
    payload: data,
  };
};

const createBookingError = (error) => {
  return {
    type: CREATE_BOOKING_ERROR,
    payload: error,
  };
};

// -------------------------------

export const updateBooking = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(updateBookingRequest());
    await axios
      .post(`${apiConfigs.API_URL}api/organization/edit-booking`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
           dispatch(createBookingSuccess(response?.data))

          setTimeout(() => {
            history.goBack();
          }, 500);
        } else {
          dispatch(updateBookingError(data));
        }
      })
      .catch((error) => {
        dispatch(updateBookingError(error));
      });
  };
};

const updateBookingRequest = () => {
  return {
    type: UPDATE_BOOKING_REQUEST,
  };
};

const updateBookingSuccess = (data) => {
  return {
    type: UPDATE_BOOKING_SUCCESS,
    payload: data,
  };
};

const updateBookingError = (error) => {
  return {
    type: UPDATE_BOOKING_ERROR,
    payload: error,
  };
};

// -----------------------------------

export const deleteBooking = (id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteBookingRequest());
    await axios
      .delete(`${apiConfigs.API_URL}api/organization/delete-booking/${id}`, {
        headers: {
          "content-type": "application/type",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(deleteBookingSuccess(response.data));
          setTimeout(() => {
            history.go(-2);
          }, 200);
        } else {
          dispatch(deleteBookingError(data));
        }
      })
      .catch((error) => {
        dispatch(deleteBookingError(error));
      });
  };
};

const deleteBookingRequest = () => {
  return {
    type: DELETE_BOOKING_REQUEST,
  };
};

const deleteBookingSuccess = (data) => {
  return {
    type: DELETE_BOOKING_SUCCESS,
    payload: data,
  };
};

const deleteBookingError = (error) => {
  return {
    type: DELETE_BOOKING_ERROR,
    payload: error,
  };
};

// --------------------------------------------

export const confirmBooking = (data, result) => {
  return async (dispatch) => {
    dispatch(confirmBookingRequest());
    await apiClient(true)
      .post(`api/organization/confirm-booking`, data)
      .then((response) => {
        const dataItem = response.data;
        if (dataItem.status === true) {
          result(dataItem);
          dispatch(confirmBookingSuccess(dataItem));
          dispatch(notificationSuccess(dataItem.message));
        } else {
          result(dataItem);
          dispatch(confirmBookingSuccess([]));
          dispatch(confirmBookingFailure(dataItem));
          dispatch(notificationFail(dataItem?.message));

        }
      })
      .catch((error) => {
        dispatch(confirmBookingSuccess([]));
        dispatch(confirmBookingFailure(error));
        dispatch(notificationFail(error.message));
      });
  };
};

const confirmBookingRequest = () => {
  return {
    type: CONFIRM_BOOKING_REQUEST,
  };
};

const confirmBookingSuccess = (data) => {
  return {
    type: CONFIRM_BOOKING_SUCCESS,
    payload: data,
  };
};

const confirmBookingFailure = (error) => {
  return {
    type: CONFIRM_BOOKING_ERROR,
    payload: error,
  };
};

// --------------------------------------------

export const changeShiftStatus = (data) => {
  return async (dispatch) => {
    dispatch(changeShiftStatusRequest());
    await apiClient(true)
      .post(`api/organization/user/change-shift-status`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(changeShiftStatusSuccess(dataItem));
      })
      .catch((error) => {
        dispatch(changeShiftStatusFailure(error));
      });
  };
};

const changeShiftStatusRequest = () => {
  return {
    type: CHANGE_SHIFT_STATUS_REQUEST,
  };
};

const changeShiftStatusSuccess = (data) => {
  return {
    type: CHANGE_SHIFT_STATUS_SUCCESS,
    payload: data,
  };
};

const changeShiftStatusFailure = (error) => {
  return {
    type: CHANGE_SHIFT_STATUS_ERROR,
    payload: error,
  };
};

// -----------------------------------------------------

// -------------------------------------

export const delTimeSheetDoc = (timesheet_id) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteTimeSheetRequest());
    await axios
      .delete(`${apiConfigs.API_URL}api/organization/delete-timesheet-by-id/${timesheet_id}`, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(deleteTimeSheetSuccess(data));
          setTimeout(() => {
            history.go(0);
          }, 2000);
        } else {
          dispatch(deleteTimeSheetError(data));
        }
        
      })
      .catch((error) => {
        dispatch(deleteTimeSheetError(error));
      });
  };
};

export const deleteTimeSheetRequest = () => {
  return {
    type: DELETE_TIMESHEET_REQUEST,
  };
};
export const deleteTimeSheetSuccess = (data) => {
  return {
    type: DELETE_TIMESHEET_SUCCESS,
    payload: data,
  };
};
export const deleteTimeSheetError = (error) => {
  return {
    type: DELETE_TIMESHEET_ERROR,
    payload: error,
  };
};

// -------------------------------------#184a7b

export const userInvitation = (data) => {
  return async (dispatch) => {
    dispatch(userInvitationRequest());
    await apiClient(true)
      .post(`api/organization/user/send-invitation`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(userInvitationSuccess(dataItem));
      })
      .catch((error) => {
        dispatch(userInvitationFailure(error));
      });
  };
};

const userInvitationRequest = () => {
  return {
    type: USER_INVITATION_REQUEST,
  };
};

const userInvitationSuccess = (data) => {
  return {
    type: USER_INVITATION_SUCCESS,
    payload: data,
  };
};

const userInvitationFailure = (error) => {
  return {
    type: USER_INVITATION_ERROR,
    payload: error,
  };
};

// --------------------------------------------

export const changePaymentStatus = (data) => {
  return async (dispatch) => {
    dispatch(changePaymentStatusRequest());
    await apiClient(true)
      .put(`api/organization/change-signee-payment-status`, data)
      .then((response) => {
        const dataItem = response.data;
        dispatch(changePaymentStatusSuccess(dataItem.message));
        dispatch(notificationSuccess(dataItem.message));
      })
      .catch((error) => {
        dispatch(changePaymentStatusFailure(error));
        dispatch(notificationFail(error.response.data.message));
      });
  };
};

const changePaymentStatusRequest = () => {
  return {
    type: CHANGE_PAYMENT_STATUS_REQUEST,
  };
};

const changePaymentStatusSuccess = (data) => {
  return {
    type: CHANGE_PAYMENT_STATUS_SUCCESS,
    payload: data,
  };
};

const changePaymentStatusFailure = (error) => {
  return {
    type: CHANGE_PAYMENT_STATUS_ERROR,
    payload: error,
  };
};

// -----------------------------------

export const reScheduleShift = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(reScheduleShiftRequest());
    await axios
      .post(`${apiConfigs.API_URL}api/organization/reschedule-booking`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data && data.status === true) {
          dispatch(reScheduleShiftSuccess(response.data));
          setTimeout(() => {
            history.go(-2);
          }, 2000);
        } else {
          dispatch(reScheduleShiftError(data));
        }
      })
      .catch((error) => {
        dispatch(reScheduleShiftError(error));
      });
  };
};

const reScheduleShiftRequest = () => {
  return {
    type: RESCHEDULE_BOOKING_REQUEST,
  };
};

const reScheduleShiftSuccess = (data) => {
  return {
    type: RESCHEDULE_BOOKING_SUCCESS,
    payload: data,
  };
};

const reScheduleShiftError = (error) => {
  return {
    type: RESCHEDULE_BOOKING_ERROR,
    payload: error,
  };
};


// -----------------------------------

export const cancelInvoice = (bookingId) => {
  const getToken = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(cancelInvoiceRequest());
    await axios
      .delete(`${apiConfigs.API_URL}api/organization/cancel-invoice/${bookingId}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + getToken,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(cancelInvoiceSuccess(data));
          localStorage.setItem('tabStatus',3);
          setTimeout(() => {
            history.go(0);
          }, 2000);
        } else {
          dispatch(cancelInvoiceError(data));
        }
        
      })
      .catch((error) => {
        dispatch(cancelInvoiceError(error));
      });
  };
};

const cancelInvoiceRequest = () => {
  return {
    type: CANCEL_INVOICE_REQUEST,
  };
};

const cancelInvoiceSuccess = (data) => {
  return {
    type: CANCEL_INVOICE_SUCCESS,
    payload: data,
  };
};

const cancelInvoiceError = (error) => {
  return {
    type: CANCEL_INVOICE_ERROR,
    payload: error,
  };
};

// -----------------------------------

export const createFilter = (data) => {
  console.log(typeof data?.start_date, "DATA START DAT")
  data.start_date = (data?.start_date && data?.start_date !== "Invalid date") ? moment(data?.start_date, 'MM-DD-YYYY').format('MM-DD-yyyy') : moment().subtract(7, 'days').format('MM-DD-yyyy');
  data.end_date = (data?.end_date && data?.end_date !== "Invalid date") ? moment(data?.end_date, 'MM-DD-yyyy').format('MM-DD-yyyy') : moment().format('MM-DD-yyyy');

  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(createFilterRequest());
    await axios
      .post(`${apiConfigs.API_URL}api/organization/create-filter`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = response.data;
        if (data.status === true) {
          dispatch(createFilterSuccess(data));
        } 
      })
      .catch((error) => {
      });
  };
};

const createFilterRequest = () => {
  return {
    type: CREATE_FILTER_REQUEST,
  };
};

const createFilterSuccess = (data) => {
  return {
    type: CREATE_FILTER_SUCCESS,
    payload: data,
  };
};
// -----------------------------------

export const deleteFilter = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(deleteFilterRequest());
    await axios
      .post(`${apiConfigs.API_URL}api/organization/delete-filter`, data, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + loggedInUser,
        },
      })
      .then((response) => {
        const data = [];
        dispatch(getFilterDataSuccess(data));
      })
      .catch((error) => {
      });
  };
};

const deleteFilterRequest = () => {
  return {
    type: DELETE_FILTER_REQUEST,
  };
};

// -----------------------------------

export const getFilterData = (moduleName) => {
  const getToken = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    dispatch(getFilterDataRequest());
    await axios
      .get(`${apiConfigs.API_URL}api/organization/get-filter-by-module?module_name=${moduleName}`, {
        headers: {
          "Content-type": "application/json",
          Authorization: "Bearer " + getToken,
        },
      })
      .then((response) => {
        const data = response.data;
        if(moduleName === 'bookings')
        {
          if (data.status === true) {
            dispatch(getFilterDataSuccess(data));
          }
        }
         if(moduleName === 'invoice-studio')
        {
          dispatch(getISFilterDataSuccess(data));
        }

        return data;
       
      })
      .catch((error) => {
      });
  };
};

const getFilterDataRequest = () => {
  return {
    type: GET_FILTER_DATA_REQUEST,
  };
};

const getFilterDataSuccess = (data) => {
  return {
    type: GET_FILTER_DATA_SUCCESS,
    payload: data,
  };
};

const getISFilterDataSuccess = (data) => {
  return {
    type: GET_IS_FILTER_DATA_SUCCESS,
    payload: data,
  };
};

//-------------------------------
export const getBookingInvoice = ({
  pageNo = 1,
  search = "",
  status = "CREATED",
  specialty = "",
  customer = "",
  candidate = "",
  site = "",
  ward = "",
  consultants = "",
  start_date = "",
  end_date = "",
  provisional_status="",
  type="",
  column_name="",
  page_limit=15
}) => {
  return async (dispatch) => {
    dispatch(confirmBookingFailure([]));
    dispatch(notificationFail(""));
    dispatch(getBookingInvoiceRequest());
    
    await apiClient(true)
      .get(
        `api/organization/booking-invoice-by-status?status=${status}&search=${search}&page=${pageNo}&speciality=${specialty}&customer=${customer}&site=${site}&ward=${ward}&consultant=${consultants}&candidate=${candidate}&start_date=${start_date}&end_date=${end_date}&provisional_status=${provisional_status}&type=${type}&column_name=${column_name}&page_limit=${page_limit}`
      )
      .then((response) => {
        dispatch(getBookingInvoiceSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getBookingInvoiceSuccess(""));
        dispatch(getBookingInvoiceError(error));
      });
  };
};

export const getBookingInvoiceRequest = () => {
  return {
    type: GET_BOOKING_INVOICE_REQUEST,
  };
};

export const getBookingInvoiceSuccess = (data) => {
  return {
    type: GET_BOOKING_INVOICE_SUCCESS,
    payload: data,
  };
};

export const getBookingInvoiceError = (error) => {
  return {
    type: GET_BOOKING_INVOICE_ERROR,
    payload: error,
  };
};
//----------------------------
export const getExpense = (id) => {
    return async (dispatch) => {
      dispatch(getExpenaseRequest());
        await apiClient(true)
            .get(`api/organization/list-expense/${id}`)
            .then((response) => {
              const data = response.data;
              if (data.status === true) {
                dispatch(getExpenseSuccess(data));
              } else {
                dispatch(getExpenseSuccess([]));
                dispatch(getExpenseError(data));
              }
            })
            .catch((error) => {
              dispatch(getExpenseError(error));
            });
    };
};

export const getExpenaseRequest = () => {
  return {
    type: GET_EXPENSE_REQUEST,
  };
};

export const getExpenseSuccess = (data) => {
  return {
    type: GET_EXPENSE_SUCCESS,
    payload: data,
  };
};
export const getExpenseError = (error) => {
  return {
    type: GET_EXPENSE_ERROR,
    payload: error,
  };
};




export const addExpense = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    await axios
      .post(`${apiConfigs.API_URL}api/organization/add-expense`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const responsedata = response.data;
        if (responsedata.status === true) {
          dispatch(createExpenseSuccess(responsedata));
          dispatch(notificationSuccess(responsedata?.message));
          if(data?.candidate_id !== ''){
            dispatch(getExpense(data?.candidate_id));
          }
        } else {
          dispatch(createExpenseError(responsedata));
          dispatch(notificationFail(responsedata?.message));
        }
      })
      .catch((error) => {
        dispatch(notificationFail(error.message));
      });
  };
};

export const superAssign = (data) => {
  const loggedInUser = localStorage.getItem("token").replace(/['"]+/g, "");
  return async (dispatch) => {
    await axios
      .post(`${apiConfigs.API_URL}api/organization/multiple-super-assign`, data, {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${loggedInUser}`,
        },
      })
      .then((response) => {
        const responsedata = response.data;
        if (responsedata.status === true) {
          dispatch(createExpenseSuccess(responsedata));
          dispatch(notificationSuccess(responsedata?.message));
          if(data?.candidate_id !== ''){
            dispatch(getExpense(data?.candidate_id));
          }
        } else {
          dispatch(createExpenseError(responsedata));
          dispatch(notificationFail(responsedata?.message));
        }
      })
      .catch((error) => {
        dispatch(notificationFail(error.message));
      });
  };
};

export const createExpenseRequest = () => {
  return {
    type: CREATE_EXPENSE_REQUEST,
  };
};
export const createExpenseSuccess = (data) => {
  return {
    type: CREATE_EXPENSE_SUCCESS,
    payload: data,
  };
};
export const createExpenseError = (error) => {
  return {
    type: CREATE_EXPENSE_ERROR,
    payload: error,
  };
};